<script setup lang="ts">
import InfoIcon from '@/assets/svg/info-icon.svg?component'
import Star from '@/assets/svg/favorite-star-2.svg?component'
import Pin from '@/assets/svg/pin-2.svg?component'
import OrderNumbers from '@/assets/svg/ascending-number-order.svg?component'
import OrderText from '@/assets/svg/ascending-alphabetical-order.svg?component'

const props = defineProps({
  icon: {
    type: String as PropType<'InfoIcon' | 'Star' | 'Pin' | 'OrderNumbers' | 'OrderText'>,
    default: 'InfoIcon',
  },
})
</script>

<template>
  <InfoIcon v-if="props.icon === 'InfoIcon'" class="ui-interface-icon ui-interface-icon--info" />
  <Star v-else-if="props.icon === 'Star'" class="ui-interface-icon ui-interface-icon--star" />
  <Pin v-else-if="props.icon === 'Pin'" class="ui-interface-icon ui-interface-icon--pin" />
  <OrderNumbers
    v-else-if="props.icon === 'OrderNumbers'"
    class="ui-interface-icon ui-interface-icon--order-text"
  />
  <OrderText
    v-else-if="props.icon === 'OrderText'"
    class="ui-interface-icon ui-interface-icon--order-numbers"
  />
</template>

<style lang="less">
@import (reference) '~/assets/less/mixins.less';
.ui-interface-icon {
  .fluid(width, 4, 6);
  fill: var(--green-1);

  &--star,
  &--pin {
    fill: transparent;
    path {
      stroke: var(--green-1);
    }
  }
  &--order-text,
  &--order-numbers {
    fill: transparent;
    .fluid(width, 4, 8);
    path {
      stroke: var(--grey-1);
    }
  }
}
</style>
