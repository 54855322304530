<script setup lang="ts">
const props = defineProps({
  color: {
    type: String,
    default: 'black',
    validator(value: string) {
      return ['black', 'red'].includes(value)
    },
  },
})
</script>

<template>
  <hr class="ui-interface-hr-ruler" :class="`ui-interface-hr-ruler--color-${props.color}`" />
</template>

<style lang="less">
@import (reference) '~/assets/less/mixins.less';
.ui-interface-hr-ruler {
  padding: 0;
  margin: 4px 0;
  border-width: 0;
  border-bottom: 1px solid var(--grey-4);

  &--color-red {
    border-color: var(--red-1);
    border-bottom-width: 2px;
  }
}
</style>
