<script setup lang="ts">
const props = defineProps({
  color: {
    type: String,
    default: 'white',
    validator(value: string) {
      return ['white', 'red', 'transparent'].includes(value)
    },
  },
  fontWeight: {
    type: Number,
    default: 400,
  },
})
</script>

<template>
  <div
    class="ui-interface-cell"
    :class="`ui-interface-cell--${props.color}`"
    :style="{
      fontWeight: props.fontWeight,
    }"
  >
    <slot />
  </div>
</template>

<style lang="less">
@import (reference) '~/assets/less/mixins.less';
.ui-interface-cell {
  display: grid;
  grid-template-columns: 1fr 1fr;
  .fluid(font-size, 5, 7);
  .fluid(padding-left, 2, 6);
  .fluid(padding-right, 2, 6);
  .fluid(padding-top, 1, 2);
  .fluid(padding-bottom, 1, 2);
  border-radius: 4px;
  .body-font();
  white-space: nowrap;

  &--white {
    background-color: #fff;
    color: var(--grey-1);
  }
  &--red {
    background-color: var(--red-3);
    color: var(--grey-1);
  }
  &--transparent {
    background-color: transparent;
  }
}
</style>
