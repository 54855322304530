<template>
  <div class="ui-interface-cell-container">
    <slot />
  </div>
</template>

<style lang="less">
@import (reference) '~/assets/less/mixins.less';
.ui-interface-cell-container {
  display: grid;
  grid-template-columns: 1fr;
  .fluid(gap, 2, 4);
}
</style>
