<script setup lang="ts">
const props = defineProps({
  graphColWidths: {
    type: Array as PropType<number[]>,
    default: () => [],
  },
})
</script>

<template>
  <div class="ui-interface-distribution-graph">
    <div
      v-for="(width, i) in props.graphColWidths"
      :key="i + 'graph_col'"
      class="ui-interface-distribution-graph__bar"
    >
      <span :style="{ width: width + '%' }" />
    </div>
    <div class="ui-interface-distribution-graph__marker" style="left: 25%">
      <span> 25% </span>
    </div>
    <div class="ui-interface-distribution-graph__marker" style="left: 50%">
      <span> 50% </span>
    </div>
    <div class="ui-interface-distribution-graph__marker" style="left: 75%">
      <span> 75% </span>
    </div>
  </div>
</template>

<style lang="less">
@import (reference) '~/assets/less/mixins.less';
.ui-interface-distribution-graph {
  position: relative;
  display: grid;
  .fluid(gap, 2, 4);

  &__marker {
    position: absolute;
    left: 25%;
    bottom: 0;
    height: 100%;
    background-color: #fff;
    width: 1.5px;

    > span {
      position: absolute;
      left: 0;
      bottom: 0;
      line-height: 1;
      transform: translate(-40%, 100%);
      .fluid(font-size, 5, 7);
      font-weight: 600;
      padding-top: 6px;
    }
  }

  &__bar {
    position: relative;
    .fluid(height, 8, 12);
    background-color: var(--yellow-1);
    border-radius: 0 1px 1px 0;

    > span {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      height: 100%;
      border-radius: 1px 0 0 1px;
      background-color: var(--green-1);
      transition: width 1s ease;
    }
  }
}
</style>
