<template>
  <div class="ui-interface-frame">
    <div class="ui-interface-frame__header"><span /><span /><span /></div>
    <div class="ui-interface-frame__content">
      <slot />
    </div>
  </div>
</template>

<style lang="less">
@import (reference) '~/assets/less/mixins.less';
.ui-interface-frame {
  &__header {
    display: flex;
    align-items: center;
    gap: 4px;
    height: 23px;
    background-color: var(--green-1);
    padding-left: 12px;
    border-radius: 12px 12px 0 0;

    span {
      display: block;
      background-color: #fff;
      height: 6px;
      width: 6px;
      border-radius: 99px;
    }
  }

  &__content {
    min-height: 100px;
    .fluid(padding-top, 6, 8);
    .fluid(padding-bottom, 6, 12);
    .fluid(padding-left, 6, 8);
    .fluid(padding-right, 6, 8);
    background-color: var(--base-2);
    border: 2px solid var(--green-1);
    border-radius: 0 0 12px 12px;
  }
}
</style>
