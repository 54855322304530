<script setup lang="ts">
import PaIcon from '@/assets/svg/payanalytics-icon.svg?component'
</script>

<template>
  <div class="hero-banner-graphic-icon">
    <PaIcon />
  </div>
</template>

<style lang="less">
@import (reference) '~/assets/less/mixins.less';
.hero-banner-graphic-icon {
  display: inline-block;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
  .fluid(padding, 12, 26);
  border-radius: 99px;

  > svg {
    width: 32px;
    fill: var(--grey-1);
  }
}
</style>
