<script setup lang="ts">
const props = defineProps({
  size: {
    type: String as PropType<StandardComponentSizeExtended>,
    default: 'm',
    validator(value: StandardComponentSizeExtended) {
      return ['m', 's'].includes(value)
    },
  },
})
</script>

<template>
  <div class="ui-interface-heading" :class="`ui-interface-heading--${props.size}`">
    <slot />
  </div>
</template>

<style lang="less">
@import (reference) '~/assets/less/mixins.less';
.ui-interface-heading {
  .heading-font();

  &--s {
    .fluid(font-size, 8, 10);
    .fluid(margin-bottom, 4, 6);
  }
  &--m {
    .fluid(font-size, 10, 14);
    .fluid(margin-bottom, 4, 8);
  }
}
</style>
