<script setup lang="ts">
const props = defineProps({
  color: {
    type: String,
    default: 'base-green',
    validator(value: string) {
      return ['base-green', 'base', 'black'].includes(value)
    },
  },
})
</script>

<template>
  <span class="ui-interface-text-skeleton" :class="`ui-interface-text-skeleton--${props.color}`">
    <slot />
  </span>
</template>

<style lang="less">
@import (reference) '~/assets/less/mixins.less';
.ui-interface-text-skeleton {
  position: relative;
  color: transparent;

  &::before {
    display: block;
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 5px;
    background-color: var(--base-green-2);
    border-radius: 99px;
  }

  &--black::before {
    background-color: var(--grey-1);
  }
  &--base-green::before {
    background-color: var(--base-green-2);
  }
  &--base::before {
    background-color: var(--base-2);
  }
}
</style>
