<script setup lang="ts">
const props = defineProps({
  color: {
    type: String,
    default: 'yellow',
    validator(value: string) {
      return ['yellow', 'green'].includes(value)
    },
  },
  size: {
    type: String,
    default: 'm',
    validator(value: string) {
      return ['m', 'l'].includes(value)
    },
  },
})
</script>

<template>
  <div
    class="ui-interface-cell-dot"
    :class="`ui-interface-cell-dot--${props.color} ui-interface-cell-dot--size-${props.size}`"
  />
</template>

<style lang="less">
@import (reference) '~/assets/less/mixins.less';
.ui-interface-cell-dot {
  display: inline-block;

  border-radius: 99px;
  background-color: var(--grey-1);

  &--size-m {
    .fluid(height, 4, 6);
    .fluid(width, 4, 6);
  }
  &--size-l {
    .fluid(height, 6, 8);
    .fluid(width, 6, 8);
  }
  &--yellow {
    background-color: var(--yellow-1);
  }
  &--green {
    background-color: var(--green-1);
  }
}
</style>
