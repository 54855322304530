<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1000 4"
    fill="none"
    style="
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      bottom: 0;
      transform: translateY(50%);
    "
  >
    <path
      d="M2 2H1312.5"
      stroke="#122233"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-dasharray="0 8"
    ></path>
  </svg>
</template>
